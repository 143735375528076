<template>
  <div
    v-if="topGenres.length > 0"
    class="relative lg:pt-16 lg:pb-12 lg:py-24 lg:px-24 xl:px-32 content-center items-center justify-center px-4 md:px-0 mt-3 py-5 lg:mt-5 grid gap-5 grid-cols-12"
  >
    <div class="col-span-12 sm:col-span-6">
      <div
        class="transition-all lazy relative shadow-md group-hover:shadow-2xl h-72 lg:h-96 w-full rounded-md background"
        style="flex: 0 0 auto;"
        :data-bg="'https://storysh.de/site/img/topGenres.webp'"
        :data-bg-hidpi="'https://storysh.de/site/img/topGenres.webp'"
      >
        <h4 class="text-left absolute bottom-0 bg-white right-0 uppercase text-l pb-3 pt-5 px-6 font-semibold text-primary">
          {{ $t('TOP_GENRES') }}
        </h4>
      </div>
    </div>
    <div 
      v-for="(genre, genreIndex) in topGenres"
      :key="genreIndex"
      class="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3"
    >
      <div class="h-72 lg:h-96">
        <article
          data-aos="fade-zoom-in"
          class="flex w-full h-full relative flex-col post-card rounded-md shadow-md hover:shadow-2xl"
        >
          <div class="flex items-center justify-between absolute top-8 right-8 left-8 z-20" />
          <a
            class="w-full h-full" 
            :href="getHref(genre)"
            :title="genre.title"
            @click.prevent="setGenre(genre)"
          >
            <div
              class="group overlay lazy overflow-hidden transition-all relative flex-initial rounded-md h-full background overlay-black"
              :data-bg="getImageSrc(genre, 'desktop')"
              :data-bg-hidpi="getImageSrc(genre, 'desktop')"
            >
              
              <div class="px-8 py-4 absolute bottom-0 right-0 left-0 flex-auto overlay-content">
                <h6>
                  <p
                    class="line-clamp-3  font-bold mt-2 block text-xl text-white group-hover:text-primary"
                  >
                    <span v-if="undefined !== genre.title">{{ genre.title }}</span>
                    <span v-else>{{ genre.completeName }}</span>
                  </p>
                </h6>
              </div>
            </div>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "IndexTopGenres",
  components: {
  },
  data() {
    return {
      topGenres: []
    };
  },
  mounted() {
    axios.get(process.env.apiURL + '/fe/api/genre/top')
      .then(response =>  {
        this.topGenres = response.data;
        setTimeout(() => {
          window.LL.update();
        }, "500");
      })
      .catch(function (error) {
      })
      .then(function () {
      });
  },
  methods: {
    getTeaser(teaser) {
      return this.truncate(teaser, 144, '...</p>');
    },
    truncate: function (text, length, suffix) {
      if (typeof text === 'undefined') {
        return '';
      }
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    getHref(genre) {
      return 'https://storysh.de/genres/' + genre.slug;
    },
    setGenre(genre) {
      window.localStorage.setItem('selected-publication-genre', JSON.stringify({
        mode: 'genre',
        value: {
          mode: "genre",
          slug: genre.slug,
          title: genre.title
        }
      }));
      window.localStorage.setItem('filter-page-genres-' + genre.slug, 1);
      window.location.replace('/genres/' + genre.slug);
    },
    getImageSrc(item, viewport) {
      if (typeof item.image.thumbnails === "undefined") {
        return item.image.src;
      }

      return item.image.thumbnails.desktop.list.img;
    },
  }
}
</script>